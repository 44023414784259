import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-screen h-screen fixed left-0 top-0 bg-black bg-opacity-70 flex flex-col justify-center items-center" }
const _hoisted_2 = {
  ref: "refIsiModal",
  tabindex: "-1",
  id: "",
  class: "w-1/2 h-4/6 rounded-3xl border-2 border-white bg-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_klik_luar = _resolveDirective("klik-luar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])), [
      [_directive_klik_luar, _ctx.handelKlikLuar]
    ])
  ]))
}