import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login")!
  const _component_ModalTemplate = _resolveComponent("ModalTemplate")!

  return (_ctx.bukaModal === true)
    ? (_openBlock(), _createBlock(_component_ModalTemplate, { key: 0 }, {
        default: _withCtx(() => [
          (_ctx.jenisModal === 'LOGIN')
            ? (_openBlock(), _createBlock(_component_Login, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}