import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.area)
    ? (_openBlock(), _createElementBlock("input", {
        key: 0,
        class: _normalizeClass(["border-2 border-gray-200 bg-gray-500 border-r-2 text-white placeholder-gray-100 text-lg p-2 min-w-[5rem] mb-2", [_ctx.lebarFull ? 'w-full' : '']]),
        onInput: _cache[0] || (_cache[0] = ev => _ctx.onBerubah(ev))
      }, null, 34))
    : (_openBlock(), _createElementBlock("textarea", {
        key: 1,
        class: _normalizeClass(["border-2 border-gray-200 bg-gray-500 border-r-2 text-white placeholder-gray-100 text-lg p-2 min-w-[5rem] mb-2", [_ctx.lebarFull ? 'w-full' : '']]),
        onInput: _cache[1] || (_cache[1] = ev => _ctx.onBerubah(ev))
      }, null, 34))
}