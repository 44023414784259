import { Directive } from "vue";


const klikLuar: Directive<any, any> = {
    mounted: (el, binding) => {
        el.eventKlikLuar = (event: any) => {
            if (!(el == event.target || el.contains(event.target))) {
                binding.value();
                console.log("klik")
            }
        }
        
        setTimeout(() => {document.addEventListener("click", el.eventKlikLuar)}, 100)
    },
    unmounted: el => {
        document.removeEventListener("click", el.eventKlikLuar)
    },
    
}

export default klikLuar