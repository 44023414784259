
import { defineComponent, ref } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';
import InputTeks from '@/Komponen/InputTeks.vue'
import {getDownloadURL, getStorage, ref as refFirebase, uploadBytes} from 'firebase/storage'
import {Berita} from "@/data/Berita"
import { doc, getFirestore, setDoc, Timestamp } from '@firebase/firestore';
import app from "@/main"
import usePengguna from "./usePengguna"
import {version} from "@/../package.json"

export default defineComponent({
  name: 'Beranda',
  components: {
    HelloWorld,
    InputTeks
  },
  setup(){
    const judulBerita = ref("");
    const linkBerita = ref("");
    const isiBerita = ref("")
    const idBerita = ref("");
    const gbr = ref<File | null>(null)

    const judulBerubah = (judulInput: string) => {
      judulBerita.value = judulInput
      idBerita.value = encodeURIComponent(judulBerita.value)
    }
    const linkBerubah = (linkInput: string) => {
      linkBerita.value = linkInput
    }
    const isiBerubah = (isiInput: string) => {
      isiBerita.value = isiInput
    }
    const simpanGbr = (e: Event) => {
      const file = (e.target as HTMLInputElement).files
      if(file !== null && file.length > 0){
        gbr.value = file[0]
      }
    }
    const pengguna = usePengguna()
    
    const uploadGbr = async (gbrUpload: File) => {   
      const storage = getStorage()
      const refFoto = refFirebase(storage, `berita/${idBerita.value}`)
      const resp = await uploadBytes(refFoto, gbrUpload)
      const downloadURL = await getDownloadURL(resp.ref)
      return downloadURL
    }
    const uploadBerita = async () => {
      app.$toast.info("Sedang mengupload")
      try{
        if(!gbr.value) return
        const urlGbr = await uploadGbr(gbr.value)
        const berita = {
          headlineBerita: judulBerita.value,
          idBerita: idBerita.value,
          isiBerita: isiBerita.value,
          linkBerita: linkBerita.value,
          linkGambar: urlGbr,
          tanggalDitambahkan: Timestamp.now() 
        } as Berita
        const db = getFirestore()
        if(!berita.idBerita) return
        await setDoc(doc(db, "databaseBerita", berita.idBerita), berita)
        app.$toast.success("Terupload")
      }catch(e){
        const er = e as Error
        app.$toast.error(`Eror upload: ${er.message}`)
      }
    }
    return{
      judulBerita,
      linkBerita,
      judulBerubah,
      linkBerubah,
      isiBerubah,
      uploadBerita,
      simpanGbr,
      pengguna,
      versi: version
    }
  }
});
