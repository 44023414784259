
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signOut } from '@firebase/auth'
import { auth } from 'firebaseui'
import { computed, defineComponent, onMounted, onUpdated } from 'vue'
import store from "@/store"

export default defineComponent({
  name: "Login",
  setup(){
    const login = computed(() => store.state.login)

    const pasangLogin = () => {
      if(!login.value){
        const uiConfig = {
        signInOptions: [
          GoogleAuthProvider.PROVIDER_ID,
          FacebookAuthProvider.PROVIDER_ID
        ]}
        if(auth.AuthUI.getInstance()){
          const ui = auth.AuthUI.getInstance()
          ui?.start('#firebaseui-auth-container', uiConfig)
        }else{
          const ui = new auth.AuthUI(getAuth())
          ui?.start("#firebaseui-auth-container", uiConfig)
        }
      }
    }
    onMounted(pasangLogin)
    onUpdated(pasangLogin)

    const logout = async () => {
      const auth = getAuth()
      await signOut(auth)
    }
    return {
      login: login,
      logout: logout
    }
  }
})
