
import { defineComponent, onMounted, onUpdated, watch } from "@vue/runtime-core";
import 'firebaseui/dist/firebaseui.css';
import store from "@/store"
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Navigasi",
  methods: {

  },
  setup(){
    const route = useRoute()
    const router = useRouter()
    watch(() => route.query, () => {
      if(route.query.m === "LOGIN"){
        store.dispatch('bukaModal', "LOGIN")
      }else if(route.query.m === undefined){
        store.dispatch('bukaModal')
      }
    })
    const onKlikProfil = () => {
      router.push({query: {m: "LOGIN"}})
    }
    return{
      onKlikProfil,
    }


  }
})
