import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./index.css"
import inisialisasiFirebase from './alat/inisialisasiFirebase'
import klikLuar from "./alat/klikLuar"
import VueToast from "vue-toast-notification"
import 'vue-toast-notification/dist/theme-sugar.css';


inisialisasiFirebase()
export default createApp(App).directive('klik-luar', klikLuar)
    .use(VueToast)
    .use(store)
    .use(router).mount('#app')
