
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue';

export default defineComponent({
  name: 'InputTeks',
  components: {
    HelloWorld,
  },
  props: {
    lebarFull: Boolean,
    area: Boolean
  },
  setup(prop){
    return{
      lebarFull: prop.lebarFull == null ? false : prop.lebarFull,
      area: prop.area == null ? false : prop.area
    }
  },
  methods: {
    onBerubah(ev: Event){
      const teks = (ev.target as HTMLInputElement).value
      this.$emit("input-berubah", teks)
    }
  }
});
