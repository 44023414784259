import { IPengguna } from "@/data/Pengguna"
import store from "@/store"
import { doc, getDoc, getFirestore } from "firebase/firestore"
import { Ref, ref, watch } from "vue"


const usePengguna = () => {
    const pengguna = ref<IPengguna | null>(null)
    const fetch = async () => {
        if(store.state.user?.uid){
            const db = getFirestore()
            const docSs = (await getDoc(doc(db, "pengguna", store.state.user.uid))).data() as any
            store.dispatch('tambahPengguna', docSs)
            pengguna.value = docSs || null
        }else{
            pengguna.value = null
        }
    }
    watch(() => store.state.user?.uid, () => {
        fetch()
    })

    return pengguna
}


export default usePengguna