
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import Navigasi from "./views/Navigasi/Navigasi.vue"
import Modal from "./views/Modal/Modal.vue"
import inisialisasiAuth from "@/alat/inisialisasiAuth"
import { Unsubscribe } from '@firebase/util';

export default defineComponent({
  name: "app-animemo-admin",
  components: {
    Navigasi,
    Modal
  },
  setup(){
    let unsub = ref<Unsubscribe | null>(null)

    onMounted(() => {
      inisialisasiAuth((getUnsub) => {unsub.value = getUnsub})
    })
    const cekUnsub = () => {
      console.log("unsub: ", unsub.value)
    }
    return {
      cekUnsub: cekUnsub
    }
  },
  methods: {

  }
})
