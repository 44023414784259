// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const inisialisasiFirebase = () => {
    const firebaseConfig = {
        apiKey: "AIzaSyB5MKLbMqllhzFQ1ho4184I5LvxuwMG-tg",
        authDomain: "anime-saku-d729a.firebaseapp.com",
        databaseURL: "https://anime-saku-d729a.firebaseio.com",
        projectId: "anime-saku-d729a",
        storageBucket: "anime-saku-d729a.appspot.com",
        messagingSenderId: "105431119434",
        appId: "1:105431119434:web:29098121b1cbedfaa058b3",
        measurementId: "G-4MYGRMCXBJ"
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
}

export default inisialisasiFirebase