import { IPengguna } from "@/data/Pengguna";
import store from "@/store";
import {onAuthStateChanged, getAuth, Unsubscribe} from "firebase/auth";
import {doc, getFirestore, onSnapshot} from "firebase/firestore"

const inisialisasiAuth = (callback: (unsub: Unsubscribe | null) => void) => {
    const auth = getAuth();
    const db = getFirestore()
    let unsub: Unsubscribe | null = null
    onAuthStateChanged(auth, (user) => {
        store.dispatch('login', user)
        if(user === null || user === undefined){
            callback(null)
            return
        }
        unsub = onSnapshot(doc(db, "pengguna", user.uid), (doc) => {
            const pengguna = (doc.data() || null) as IPengguna | null
            store.dispatch('tambahPengguna', pengguna)
        })
        console.log("unsub dalam: ", unsub)
        callback(unsub)
    });
}


export default inisialisasiAuth