import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "min-w-full min-h-screen bg-black mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigasi = _resolveComponent("Navigasi")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navigasi),
    _createVNode(_component_Modal),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "text-white bg-black",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cekUnsub && _ctx.cekUnsub(...args)))
      }, " tombol test "),
      _createVNode(_component_router_view, { class: "min-w-full min-h-full" })
    ])
  ], 64))
}