
import {  computed, defineComponent, ref } from 'vue';
import ModalTemplate from "./ModalTemplate.vue";
import Login from "./Login.vue";
import { useStore } from 'vuex';
// import store from '@/store';

export default defineComponent({
  name: "Modal",
  components: {
    ModalTemplate,
    Login
  },
  setup(){
    const store = useStore()
    return {
      bukaModal: computed(() => store.state.bukaModal),
      jenisModal: computed(() => store.state.jenisModal)
    }
  },
})
