
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: "ModalTemplate",
  props: {
    bukaModal: Boolean
  },
  setup(_){
    const router = useRouter()
    const handelKlikLuar = () => {
      router.replace({query: {m: undefined}})
    }
    return {
      handelKlikLuar: handelKlikLuar,
    }
  }

})
